define("discourse/plugins/discourse-ai-topic-summary/discourse/components/ai-topic-summary", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _ajax, _ajaxError, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.show}}
  <div class="ai-topic-summary-component">
    <div class="ai-summary-box">
      <span class="ai-summary-title" title={{i18n "ai_topic_summary.heading.title"}}>{{i18n
          "ai_topic_summary.heading.text"
        }}</span><span class="ai-summary">{{this.text}}</span>
      <span class="ai-summary-downvote">
      <DButton
        class="ai-summary-downvote-button"
        @icon="thumbs-down"
        @title="ai_topic_summary.downvote"
        @disabled={{this.voted}}
        @action={{action this.downVote}}
      /><span class="ai-summary-downvote-count">{{this.localDownVotes}}</span>
      </span>
    </div>
  </div>
  {{/if}}
  */
  {
    "id": "FG57MRHc",
    "block": "[[[41,[30,0,[\"show\"]],[[[10,0],[14,0,\"ai-topic-summary-component\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"ai-summary-box\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"ai-summary-title\"],[15,\"title\",[28,[37,1],[\"ai_topic_summary.heading.title\"],null]],[12],[1,[28,[35,1],[\"ai_topic_summary.heading.text\"],null]],[13],[10,1],[14,0,\"ai-summary\"],[12],[1,[30,0,[\"text\"]]],[13],[1,\"\\n    \"],[10,1],[14,0,\"ai-summary-downvote\"],[12],[1,\"\\n    \"],[8,[39,2],[[24,0,\"ai-summary-downvote-button\"]],[[\"@icon\",\"@title\",\"@disabled\",\"@action\"],[\"thumbs-down\",\"ai_topic_summary.downvote\",[30,0,[\"voted\"]],[28,[37,3],[[30,0],[30,0,[\"downVote\"]]],null]]],null],[10,1],[14,0,\"ai-summary-downvote-count\"],[12],[1,[30,0,[\"localDownVotes\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"i18n\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-ai-topic-summary/discourse/components/ai-topic-summary.hbs",
    "isStrictMode": false
  });
  let AiTopicSummaryComponent = _exports.default = (_class = class AiTopicSummaryComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "localDownVotes", _descriptor4, this);
      _initializerDefineProperty(this, "downVotes", _descriptor5, this);
      _initializerDefineProperty(this, "text", _descriptor6, this);
      _initializerDefineProperty(this, "topic_id", _descriptor7, this);
      _initializerDefineProperty(this, "voted", _descriptor8, this);
      this.topic_id = this.router.currentRoute.parent.params.id;
      const topicAiSummaryDataPath = `/ai_topic_summary/ai_summary/${this.topic_id}.json`;
      (0, _ajax.ajax)(topicAiSummaryDataPath).then(response => {
        if (response.ai_summary !== null) {
          this.text = response.ai_summary.text;
          this.downVotes = response.ai_summary.downvoted;
        }
      });
      this.localDownVotes = typeof this.downVotes !== 'undefined' ? this.downVotes.length || 0 : 0;
      if (this.currentUser) {
        this.voted = typeof this.downVotes !== 'undefined' ? this.downVotes.includes(this.currentUser.id) : false;
      } else {
        this.voted = true;
      }
    }
    get show() {
      return this.siteSettings.ai_topic_summary_enabled && this.text && this.currentUser;
    }
    downVote() {
      this.localDownVotes++;
      this.voted = true;
      (0, _ajax.ajax)("/ai_topic_summary/downvote", {
        type: "POST",
        data: {
          username: this.currentUser.username,
          topic_id: this.topic_id
        },
        returnXHR: true
      }).catch(function (error) {
        (0, _ajaxError.popupAjaxError)(error);
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "localDownVotes", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "downVotes", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "text", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "topic_id", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "voted", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "downVote", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "downVote"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AiTopicSummaryComponent);
});